
export default {

  /*
  ATTENTION! Do not forget to define VALIDATION rules in documentation:
  https://insured.atlassian.net/wiki/spaces/VAKUUTUSTI/pages/290947073/Validation+rules+F+B
  */

  data () {
    return {

      // mixinValidation_registrationNumber_inputMaxLength: 6,

      mixinFlagAllowValidate:  { registrationNumber:  false         }, /*mixinValidationAllows*/     // Stop from VALIDATION at the first INPUT editing
      mixinValidError:         { registrationNumber: ''             }, /*mixinValidationErrorTexts*/ // ERROR MSG of VALIDATION
      mixinClassVal:           { registrationNumber: 'display-hide' }, /*mixinValidationClasses*/    // SHOW/HIDE ERROR BOX
      mixinValidationOptional: { registrationNumber:  false         },                               // For making INPUT as an OPTIONAL:

    } // return
  }, // data


  methods: {
		

    //---  -----------------------------------------------------------------------------------------------
    mixinValidation_registrationNumber (inputValue) { 

      var inputType = 'registrationNumber';

      // Need to allow VALIDATION after finding empty INPUTS at pushing "SUBMIT" button 
      if (this.mixinValidError[inputType]) this.mixinFlagAllowValidate[inputType] = true;

      if (this.mixinFlagAllowValidate[inputType]) {
        this.mixinValidation_registrationNumber_Check(inputValue);
        this.mixinValidationErrorBoxShowHide(inputType);
      }

    },


    //--- Validation starts only after user has filles INPUT for the first time ---------------------------
    mixinValidation_registrationNumber_Start (inputValue) {

      var inputType = 'registrationNumber';

      this.mixinFlagAllowValidate[inputType] = true;
      this.mixinValidation_registrationNumber(inputValue);

    },

 
    //-----------------------------------------------------------------------------------------------------
    //--- INNER WORKER ------------------------------------------------------------------------------------
    mixinValidation_registrationNumber_Check (inputValue) {

      var inputType = 'registrationNumber';

      var validRegex = (value) => { 
        // 5 or 6 numbers
        var re = /^\s*[0-9]{5,6}\s*$/gm;
        return re.test(value);
      }

      if (!inputValue) { // If empty
        this.mixinValidationErrorIfEmpty(inputType);
      } 
      else {
        if (!validRegex(inputValue)) {
          this.mixinValidError[inputType] = 'Tarkista rekisterinumero';
        }
        else {
          this.mixinValidationCommon_ErrorMsgReset(inputType);
        }
      }
  
    },


  } // methods

}